
@media print {
[dir] body {
    background-color: #f8f8f8;
}
.title-page {
    font-size: 1rem;
}
[dir] .card {
    background-color: #fff;
    border: 1px solid #000;
}
.no-print {
    display: none !important;
}
#printContainer {
    display: block;
}
}
#printContainer {
  display: none;
}
